import argentine from './argentine.png';
import unitedState from './united_state.png';
import france from './france.png';

const flags: any= {};

flags['argentine'] = argentine;
flags['united_state'] = unitedState;
flags['france'] = france;

export default flags;