 import React, {useState, FormEvent } from 'react'
import { Link } from 'react-router-dom';
import FooterNav from '../../components/FooterNav';
import Nav from '../../components/Nav';
// const inputClass = `appearance-none bg-white mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1`;

// const spanClass = `block text-md font-medium text-slate-700`;


function SearchView() {
  const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
    console.log(form);
  };
  
  const handlerChange = (e: any) => {
    setForm(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }))
  }
  
  const [form, setForm] = useState({})
  return (
    <>
      <Nav />
      <div className='my-10'>
        <h3 className='text-3xl font-bold text-center text-sky-500'>Describe your language partner</h3>
        <div className='flex justify-center'>
          <form onSubmit={handleSubmit}  id="register" action="" className='max-w-md w-full mx-2 my-5 space-y-4 flex flex-col' >
            <div className='flex flex-col mb-4'>
              <label htmlFor='username' className='ml-3 mb-1 text-xs text-gray-400'>Speaks</label>
              <select onChange={handlerChange} className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 " name="speaks">
                <option value="">select</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>

            <div className='flex flex-col mb-4'>
              <label htmlFor='learning' className='ml-3 mb-1 text-xs text-gray-400'> Learning</label>
              <select onChange={handlerChange} className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 " name="learning">
              <option value="">-select-</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
                </select>
            </div>
            <div>
              <label htmlFor='from' className='ml-3 mb-1 text-xs text-gray-400'>Level: From - To</label>
              <div className='flex space-x-5'>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange}name="from" >
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
                <select className=" appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="to">
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </div>
            </div> 
            <div>
              <label htmlFor='country' className='ml-3 mb-1 text-xs text-gray-400'>Country</label>
              <div className='flex space-x-5'>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange}name="country" >
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="state">
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </div>
            </div>

            <div>
              <label htmlFor='level' className='ml-3 mb-1 text-xs text-gray-400'>Level</label>
              <div className='flex space-x-5'>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="levelFrom" >
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="levelTo">
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </div>
            </div> 
            <div>
              <label htmlFor='age' className='ml-3 mb-1 text-xs text-gray-400'>Age</label>
              <div className='flex space-x-5'>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="ageFrom" >
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
                <select className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 grow" onChange={handlerChange} name="ageTo">
                  <option value="">-select-</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </div>
            </div> 
            <div className='flex flex-col mb-4'>
              <label htmlFor='gender' className='ml-3 mb-1 text-xs text-gray-400'>Gender</label>
              <select onChange={handlerChange} className="appearance-none bg-white border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 " name="gender">
                <option value="">select</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>

            <div>
              <Link to='/search/results'>
                <button type='submit' className='flex justify-center align-center m-auto w-full mt-10 text-white text-center border-2 text-xs font-bold px-14 py-4 rounded-3xl active:bg-violet-300  bg-violet-600 active:text-white transition-all'>Search</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <FooterNav/>
    </>
  )
};

export default SearchView;
