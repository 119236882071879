import { MockUsers } from '../../utils/mockData';
import Nav from '../../components/Nav'
import avatars from '../../assets/images';
import ButtonSendMessage from '../../components/Form/Buttons/ButtonSendMessage';
import flags from '../../assets/images/flags';
import { calculateAge, calculteLastConnection } from '../../utils/utils';
import FooterNav from '../../components/FooterNav';

const SearchResultsView = () => {
  return (
    <>
    <Nav></Nav>
      <div>
        <div className='m-auto max-w-xl space-y-5'>
          {MockUsers.sort((a:any, b:any) => b.last_connection - a.last_connection ).map(el => (
            <div className='m-2 shadow-xl rounded-lg p-5 pb-10 relative border border-gray-200 font-poppins'>
              <div className='flex grow'>
                <div className='w-fit'>
                  <div className='relative w-28'>
                    <img className="w-28 rounded-full border border-4" src={avatars[el.avatar_id].img} alt="" />
                    <img className="w-8 rounded-full absolute top-0 right-0 border border-4" alt={el.country} src={flags[el.country]} />
                  </div>
                  <div>
                    <h3 className='font-poppins text-violet-600 font-bold text-sm text-center mt-2'>{el.languages[0].name}</h3>
                    <h3 className='font-poppins text-black font-bold text-sm text-center'>{(calculteLastConnection(el.last_connection)) || 'Today'} {(calculteLastConnection(el.last_connection)) === 0 ? null : ((calculteLastConnection(el.last_connection) > 1) ? 'days ago': 'day ago')} </h3>
                  </div>
                </div>
                <div className='pl-5 min-w-[60%] mb-2'>
                  <h3 className='font-poppins text-black font-bold text-[20px] mb-[-5px]'>{el.username}</h3>
                  <span className='text-xs text-slate-500 capitalize'>{el.gender} - </span><span className='text-xs text-slate-500'>{calculateAge(el.date_of_birth) + " years"}</span>
                  <div>
                    
                    <div className='mt-1'>
                      {el.languages.map((el, i) => {
                        if(i === 0) 
                          return null
                        return (
                          <div>
                            <h3 className='font-poppins text-black font-bold text-xs inline-block mr-2'>{el.name} </h3>
                            {Array(el.level).fill(null).map( el =>  <div className='w-2 h-2 rounded-full mr-[2px] ml-[2px] appearance-none bg-[#3A98DD] inline-block'></div>)}
                            {(el.level === 4 ? null : (
                              Array(4 - el.level ).fill(null).map( el => <div className='w-2 h-2 rounded-full mr-[2px] ml-[2px] appearance-none bg-transparent border border-2 inline-block'></div> )
                            ))}
                          </div>)
                        })
                      }
                    </div>
                  </div>
                  <p className='text-sm	leading-tight font-light text-slate-500 my-3 '>{el.description}</p>
                </div>
              </div>
                <ButtonSendMessage state={true} userId={el.id} />
            </div>
          ))}
        </div>
      </div>
      <FooterNav/>
    </>
  )
};

export default SearchResultsView;