import React, {useState, FormEvent, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppCtx } from '../../store/storeProvider';

const ChooseTheLanguageYouSpeakView = () => {

  const [form, setForm] = useState<Object[]>([{}]); 

  let navigate = useNavigate();
  const { setLanguages } = useContext<any>(AppCtx);

  const handleMore = (e: any): void => {
    setForm(state => [...state,{}])
  }

  const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
    setLanguages(form)
    navigate("/into-login");
    return;
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    
    // const requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: JSON.stringify(form),
    // };
    
    // fetch("https://backend-api-user-v2.vercel.app/user", requestOptions)
    //   .then(response => {
    //     response.text();
    //     setIsLoading(true);
    //   })
    //   .then(result => {
    //     navigate("/create-profile");
    //     return result
    //   })
    //   .catch(error => { 
    //     setIsLoading(false);
    //     return error;
    //   });
  }

  const handlerChange = (e : any) : void => {
    const position = Number(e.target.name.split('-')[0]);
    const key: string = e.target.name.split('-')[1];

    setForm(state => {  
      if(state.length < (position + 1) ){
        return [
          ...state,
          {
            [key]: e.target.value
          }
        ]
      }
      
      const copy = state.map((el,i) => {      
        if(i === position){
          return ({ 
            ...el,
            [key]: e.target.value
          })
        } else {
          return el
        }
      })  
      return copy
    })
  }

  return (
    <div className="flex flex-col justify-center items-center mt-24 w-auto h-auto">
      <div className="text-lg text-sky-500 font-bold w-48 text-center">Choose the languages you speak</div>
      <form onSubmit={handleSubmit}>
        {form.map((el,i) => (<div key={i} className='flex mb-4 mt-8' onChange={handlerChange}>
          <div className='flex flex-col ml-6 mr-2' >
            <label htmlFor="country" className='ml-3 mb-1 text-xs text-gray-400'>Language</label>
            <select name={i + "-name"} className='border rounded-2xl text-sm text-gray-400 italic pr-8 p-4 w-36 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'>
              <option value="-Select-">-Select-</option>
              <option value="English">English</option>
            </select>
          </div>
          <div className='flex flex-col mr-6 ml-2'>
            <label htmlFor="town" className='ml-3 mb-1 text-xs text-gray-400'>Skill</label>
            <select name={i + "-level"} className='border rounded-2xl text-sm text-gray-400 italic pr-8 p-4 w-36 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'>
              <option value="-Select-">-Select-</option>
              <option value="1">Skill</option>
            </select>
          </div>
        </div>))}

        <div className='text-sky-500 text-xs mt-4 cursor-pointer' onClick={handleMore}>Add one more</div>
        <div className='text-center'>
          <button className='flex m-auto mt-10 text-slate-400 border-2 text-xs font-bold px-14 py-4 rounded-3xl active:bg-violet-600 active:text-white transition-all'>Finish your profile</button>
        </div>
      </form>
      <div className='mt-6'>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#3A98DD]  inline-block'></div>
      </div>
    </div>
  )
}

export default ChooseTheLanguageYouSpeakView;