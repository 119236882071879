import React from 'react';
import { useNavigate } from "react-router-dom";
import avatars from '../../assets/images';

function Nav() {
  const navigate = useNavigate()
  return (
    <nav className='bg-white text-black p-3 flex justify-between border font-poppins sticky top-0 z-10'>
      {/* <button onClick={() => navigate(-1)} className="my-auto" >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
      </button> */}
      <div className='w-full flex items-center'>
      <button className="bg-gradient-to-tr from-[#863EED] justify-center items-center via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] rounded-full p-[2.5px]" onClick={() => navigate ("/profile")}>
        <img className="w-8 h-8 rounded-full " src={avatars[0].img} alt="" />
      </button>
        <div className='ml-2'>
          <p className='text-violet-600 text-[12px] m-0 p-0'>Rodrigo Bueno</p>
          <p className='text-slate-500 text-[12px] m-0 p-0'>#2222</p>
        </div> 
      </div>
      <button className="my-auto">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </nav>
  )
}

export default Nav;