
export const calculteLastConnection = (date: Date) : number | string => {
  let today = new Date();
  let date_to_reply = date;
  let timeinmilisec = today.getTime() - date_to_reply.getTime();
  return Math.floor(timeinmilisec / (1000 * 60 * 60 * 24));
};

export const calculateAge = (date: Date) => {
  let diff_ms = Date.now() - date.getTime();
  let age_dt = new Date(diff_ms);   
  return Math.floor(age_dt.getUTCFullYear() - 1970);
}