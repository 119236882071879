import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppCtx } from '../../store/storeProvider';
import avatars from '../../assets/images';


const CreateProfileView = () => {

  const {state: appContext} = useContext<any>(AppCtx);

  return (
    <div className='w-60 flex justify-center m-auto items-center h-auto mt-24 mb-24 flex flex-col'>
      <div className='text-sky-500 font-bold text-lg text-center w-28'>Create your profile</div>
    {appContext?.avatarID === undefined ? (<div className='w-40 h-40 mt-6 flex items-center justify-center cursor-pointer bg-gradient-to-tr from-[#863EED] via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] rounded-full'>
        <Link to="/choose-avatar">
          <div className='bg-white rounded-full w-36 h-36 flex justify-center items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          </div>
        </Link>
      </div>) : (<div className='w-40 h-40 mt-6 flex items-center justify-center cursor-pointer bg-gradient-to-tr from-[#863EED] via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] rounded-full'>
        <Link to="/choose-avatar">
          <div className='bg-white rounded-full w-36 h-36 flex justify-center items-center overflow-hidden'>
            <img src={avatars.find(el => el.id === appContext.avatarID )?.img} alt="avatar" />
          </div>
        </Link>
      </div>)}
      <div className="text-xs font-bold mt-6">Choose your avatar</div>
      <div className='text-center'>
        <Link to="/tell-us-about-yourself-view">
          <button className='flex m-auto mt-6 text-slate-400 border-2 text-xs font-bold px-14 py-4 rounded-3xl active:bg-violet-600 active:text-white transition-all'>Next step</button>
        </Link>
      </div>
      <div className='mt-6'>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#3A98DD] inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
      </div>
    </div>
  )
}

export default CreateProfileView;