import React from 'react';
import logo from '../../assets/svg/logo.svg';

const IntoLoginView = () => {
  return (
    <div className="bg-gradient-to-tr from-[#863EED] justify-center items-center via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] min-h-screen flex flex-col justify-content content-center">
      <div className="flex flex-col p-4 px-12 max-w-md">
        <img src={logo} alt="logo" className='' />
        <p className='font-poppins text-white text-center mt-10'>Talk, chat, exchange and learn<br/> any language</p>
      </div>
      <div>
        <button className='block mt-8 hover:bg-[#F7F8FA] hover:text-[#863EED] border-2 text-white font-poppins text-bold  py-4 px-8 w-64 mx-auto rounded-full'>Sing In</button>
        <button className='block mt-8 text-white hover:bg-[#F7F8FA] hover:text-[#863EED] border-2 font-poppins text-bold  py-4 px-8 w-64 mx-auto rounded-full'>Sign in with Google</button>
        <button className='block mt-8 text-white hover:bg-[#F7F8FA] hover:text-[#863EED] border-2 font-poppins text-bold  py-4 px-8 w-64 mx-auto rounded-full'>Create new account</button>
      </div>
    </div>
  )
}

export default IntoLoginView;