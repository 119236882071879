

export const sendContactEmail = async (payload :string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(payload),
  };
  
  fetch("https://backend-api-user-v2.vercel.app/send/contact", requestOptions)
    .then(response => {
      response.text();
    })
    .then(result => {
      return result
    })
    .catch(error => { 
      return error;
    });
}
