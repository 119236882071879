import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import { AppCtx } from '../../store/storeProvider';

const TellUsAboutYourselfView = () => {

  const [textCount, setTextCount] = useState(0);
  const [textLimitError, setTextLimitError] = useState("");

  const { setAboutYourself } = useContext<any>(AppCtx);

  const handleTextCountChange = (e: any) => {
    if(e.target.value.length >= 280) {
      setTextCount(e.target.value.length);
      setTextLimitError("Ups, you have already reached the maximum of characters.");
    } else {
      setAboutYourself( e.target.value );
      setTextCount(e.target.value.length);
      setTextLimitError("");
    }
  }

  return (
    <div className="flex flex-col justify-center items-center mt-24 mb-24">
      <div className='text-lg text-sky-500 font-bold w-28 text-center'>Tell us about yourself</div>
      <div className='flex flex-col ml-6 mr-6 mb-4'>
        <input type="text" minLength={0} maxLength={280} onChange={handleTextCountChange} placeholder="Write the best of you" className="w-72 border-2 mt-6 p-4 rounded-2xl pl-3 placeholder:text-gray-400 text-xs focus:outline-none" autoFocus/>
        <div className='text-gray-400 text-xs text-end mr-4 mt-1'>{textCount}/280</div>
      </div>
      <div className='w-60 text-center text-red-500'>{textLimitError}</div>
      <div className='text-sm text-sky-500 mt-16 cursor-pointer'>We are almost done</div>
      <div className='text-center'>
        <Link to="/choose-the-language-you-speak-view">
          <button className='flex m-auto mt-6 text-slate-400 border-2 text-xs font-bold px-14 py-4 rounded-3xl active:bg-violet-600 active:text-white transition-all'>Next step</button>
        </Link>
      </div>
      <div className='mt-6'>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#3A98DD]  inline-block'></div>
          <div className='w-2 h-2 rounded-full mr-1 ml-1 cursor-pointer appearance-none bg-[#CAD3EA] inline-block'></div>
      </div>
    </div>
  )
}

export default TellUsAboutYourselfView;