
import a from './CP-1 1.png'
import b from './CP-12 1.png'
import c from './CP-11 1.png'
import d from './CP-2 1.png'
import e from './CP-10 1.png'
import f from './CP-6 1.png'
import g from './CP-7 1.png'
import h from './CP-8 1.png'
import i from './CP-9 1.png'
import j from './CP-5 1.png'
import k from './CP-3 1.png'
import l from './CP-4 1.png'

const avatars = [
  {
    id: 1,
    img: a
  },
  {
    id: 2,
    img: b
  },
  {
    id: 3,
    img: c
  },
  {
    id: 4,
    img: d
  },
  {
    id: 5,
    img: e
  },
  {
    id: 6,
    img: f
  },
  {
    id: 7,
    img: g
  },
  {
    id: 8,
    img: h
  },
  {
    id: 9,
    img: i
  },
  {
    id: 10,
    img: j
  },
  {
    id: 11,
    img: k
  },
  {
    id: 12,
    img: l
  }
]

export default avatars;