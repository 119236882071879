import React, { useState, FormEvent } from 'react';
import { useNavigate } from "react-router-dom";

const IntoLogin = () => {
  const [form, setForm] = useState({}); 
  const [isLoading, setIsLoading] = useState(false); 
  let navigate = useNavigate();

  const handleSubmit = async (e : FormEvent) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(form),
    };
    
    fetch("https://backend-api-user-v2.vercel.app/auth", requestOptions)
      .then(response => {
        setIsLoading(true);
        return response.text();
      })
      .then(result => {
        navigate("/overview");
        return result
      })
      .catch(error => { 
        setIsLoading(false);
        return error
      });
  };

  const handlerChange = (e: any) => {
    setForm(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }));  
  }
  

  return (
    <>
      {isLoading ? <h1>isLoading...</h1> : (
      <div className='flex flex-col max-w-xl m-auto h-auto rounded-3xl'>
        <div className=' md:w-60 lg:w-48 ml-7 mt-24 text-sky-500 font-bold text-2xl'>Sign in to <br/>your account</div>
        <form className='mt-8 mb-28 flex flex-col' onSubmit={handleSubmit}>
          <div className='flex flex-col ml-6 mr-6 mb-4'>
            <label htmlFor='email' className='ml-3 mb-1 text-xs text-gray-400'>E-mail address</label>
            <input onChange={handlerChange} name="email" type="email" placeholder="E-mail" className="border-2 p-4 rounded-2xl pl-11 mb-4 text-sm placeholder:text-slate-900 relative"/>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-1 text-slate-400 absolute mx-4 my-9">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/></svg>
          </div>
          <div className='flex flex-col ml-6 mr-6'>
            <label htmlFor='password' className='ml-3 mb-1 text-xs text-gray-400'>Your Password</label>
            <input onChange={handlerChange} name="password" type="password" placeholder="Password" className="border-2 p-4 rounded-2xl mb-6 text-sm placeholder:text-slate-900 relative pl-11"/>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-1 text-slate-400 absolute mx-4 my-9">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"/></svg>
          </div>
          <div className='text-xs text-sky-500 flex justify-end mr-10 cursor-pointer hover:underline'>Forgot password?</div>
          <button className='flex m-auto mt-7 text-slate-400 border-2 text-xs font-bold px-14 py-4 rounded-3xl hover:bg-violet-600 hover:text-white transition-all'>Sign in</button>
        </form>
      </div>)}
    </>
  )
}

export default IntoLogin;