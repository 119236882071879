import React from 'react'
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav'
// import logo from '../../assets/svg/logo.svg'


function OverviewView() {
  return (
    <div>
      <Nav />
      <div className="items-center flex flex-col justify-content content-center">
        {/* <div className="flex flex-col pt-4 max-w-xs mb-4 ">
          <img src={logo} alt="logo" className='absolute w-1/3 block top-[10%] left-[34%]  -z-50' />
          {/* <p className='font-poppins text-white text-center'>Talk, chat, exchange and learn<br/> any language</p> */}
        {/* </div> */}
        <div>
        <Link to='/search'>
          <button className='md:mid-w-[40%] min-w-[68%] flex ml-auto justify-center items-center text-white border-2 text-xs px-11 py-3 mt-2 rounded-3xl active:bg-violet-300 bg-violet-600 active:text-white transition-all'> 
            <p className='font-poppins'>Find a partner</p>
            <span className="ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
              </svg>
            </span>
          </button>
        </Link>
        </div>
      </div>
    </div>
  )
}

export default OverviewView