import {StoreProvider} from '../store/storeProvider'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';
import { HomeView,SearchResultsView,SearchView,LoginView,MessageView,RegisterView,IntoLoginView, IntoLogin, CreateNewAccountView, CreateProfileView, ChooseAvatarView, TellUsAboutYourselfView, ChooseTheLanguageYouSpeakView, OverviewView } from '../views';


function App() {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<HomeView />}/>
          <Route path="/overview" element={<OverviewView />}/>
          <Route path="/login" element={<LoginView />}/>
          <Route path="/register" element={<RegisterView />}/>
          <Route path="/search" element={<SearchView />}/>
          <Route path="/search/results" element={<SearchResultsView />}/>
          <Route path="/message" element={<MessageView />}/>
          <Route path="/into-login-view" element={<IntoLoginView />}/>
          <Route path="/into-login" element={<IntoLogin />}/>
          <Route path="/create-new-account" element={<CreateNewAccountView/>}/>
          <Route path="/create-profile" element={<CreateProfileView/>}/>
          <Route path="/choose-avatar" element={<ChooseAvatarView/>}/>
          <Route path="/tell-us-about-yourself-view" element={<TellUsAboutYourselfView/>}/>
          <Route path="/choose-the-language-you-speak-view" element={<ChooseTheLanguageYouSpeakView/>}/>
          <Route
            path="*"
            element={<HomeView />}
          />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
