import React, { useState, useContext } from 'react';
import {Link} from 'react-router-dom';
import avatars from '../../assets/images'
import { AppCtx } from '../../store/storeProvider';


const ChooseAvatarView = () => {
  // const [select, setSelect] = useState<NewObj[]>(avatar);
  const [selected, setSelected] = useState<number>();
  const {selectAvatar} = useContext<any>(AppCtx);
  
  const handleSelected = (e: any) => {
    e.stopPropagation();    
    selectAvatar(Number(e.target.id))
    setSelected(Number(e.target.id));
  }

  return (
    <div className='mt-24 mb-24 flex flex-col justify-center items-center'>
      <div className='flex mb-6 grid grid-cols-3'>
        {avatars.map((item) => {
          if(selected === undefined) {
            return (<div key={item.id} className='p-1 mx-1 my-1 rounded-full'>
              <div className='w-20 h-20 cursor-pointer rounded-full border'>
                <img src={item.img} alt="avatar" className='rounded-full' id={String(item.id)} onClick={handleSelected} />
              </div>
            </div>)
          }
          if(item.id === selected) {
            return (<div key={item.id} className='p-1 mx-1 my-1 bg-gradient-to-tr from-[#863EED] via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] rounded-full'>
              <div className='w-20 h-20 cursor-pointer rounded-full'>
                <img src={item.img} alt="avatar" className='rounded-full' id={String(item.id)} onClick={handleSelected}/>
                {selected && <div className='w-10 h-10 rounded-full flex justify-center items-center bg-green-500 ml-5 relative z-10 bottom-4'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>}
              </div>
            </div>)
          }
          return (<div key={item.id} className='p-1 mx-1 my-1 rounded-full'>
            <div className='w-20 h-20 cursor-pointer rounded-full brightness-50'>
              <img src={item.img} alt="avatar" className='rounded-full' id={String(item.id)} onClick={handleSelected}/>
            </div>
          </div>)
        })}
      </div>
      {/* <div className='text-xs font-bold'>Choose your favorite</div> */}
      {selected ? <Link to="/create-profile" className='text-md text-green-600 font-bold'>Confirm</Link> : <div className='text-xs font-bold'>Choose your favorite</div>}
      
    </div>
  )
}

export default ChooseAvatarView;