import React, { useState, FormEvent, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AppCtx } from '../../store/storeProvider';

const CreateNewAccountView = () => {
  const [form, setForm] = useState({}); 
  const [isLoading, setIsLoading] = useState(false); 
  const { setInfo } = useContext<any>(AppCtx);
  let navigate = useNavigate();

  const handleSubmit = async (e : FormEvent) => {
    e.preventDefault();
    // navigate("/create-profile");
    // return undefined;
    setInfo(form)
    navigate("/create-profile");
    // return;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(form),
    };
    
    fetch("https://backend-api-user-v2.vercel.app/user111", requestOptions)
      .then(response => {
        response.text();
        setIsLoading(true);
      })
      .then(result => {
        navigate("/create-profile");
        return result
      })
      .catch(error => { 
        setIsLoading(false);
        return error;
      });
  };

  const handlerChange = (e: any) => {
    setForm(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }))
  }
  

  return (
    <div className='flex flex-col max-w-xl m-auto h-auto rounded-3xl'>
      {isLoading ? <h1>Loading...</h1> : (
          <>
            <div className='w-28 md:w-60 lg:w-48 ml-7 mt-24 text-sky-500 font-bold text-lg'>Create a new account</div>
              <form onSubmit={handleSubmit}>      
              <div className='mt-8 mb-28 flex flex-col'>
                <div className='flex flex-col ml-6 mr-6 mb-4'>
                  <label htmlFor='username' className='ml-3 mb-1 text-xs text-gray-400'>Username</label>
                  <input onChange={handlerChange} type="text" name="username" id="usename" placeholder="Convopal" className="border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 pl-11"/>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-gray-400 absolute my-9 ml-4">
                  {/*// eslint-disable-next-line*/}
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </div>
                <div className='flex flex-col ml-6 mr-6 mb-4 mt-3'>
                  <label htmlFor='email' className='ml-3 mb-1 text-xs text-gray-400'>E-mail address</label>
                  <input onChange={handlerChange} type="email" name="email" id="email" placeholder="youremail@gmail.com" className="border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic focus:outline-none focus:border-violet-600 pl-11"/>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-gray-400 absolute my-9 ml-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                </div>
                <div className='flex flex-col ml-6 mr-6 mb-4 mt-3 relative'>
                  <div className='flex justify-between'>
                    <label htmlFor='password' className='ml-3 mb-1 text-xs text-gray-400'>Your Password</label>
                    {/* <label className='text-xs mr-4 text-red-500 italic'>Enter valid information</label> */}
                  </div>
                  <input onChange={handlerChange} type="password" name="password" id="password" placeholder="************" className="border p-4 rounded-2xl text-sm placeholder:text-gray-400 active:text-red-500 italic focus:outline-none focus:border-violet-600 invalid:border-red-600 pl-11"/>
                  <div className="absolute flex items-center left-0 justify-between">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-gray-400 my-9 ml-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                  </div>
                  <div className='absolute right-0 mt-8'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-gray-400 mr-4 cursor-pointer active:stroke-green-400 mt-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-gray-400 mr-4 active:stroke-red-500 mt-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg> */}
                  </div>
                </div>
                <div className='flex mb-4 justify-between mt-3'>
                  <div className='flex flex-col ml-6'>
                    <label htmlFor="date" className='ml-3 mb-1 text-xs text-gray-400'>Date</label>
                    <input onChange={handlerChange} type="date" name="date" id="date" placeholder='**/**/****' className='border p-4 rounded-2xl text-sm placeholder:text-gray-400 italic w-40 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'/>
                  </div>
                  <div className='flex flex-col mr-6'>
                    <label htmlFor="gender" className='ml-3 mb-1 text-xs text-gray-400'>Gender</label>
                    <select onChange={handlerChange} name="gender" className='border rounded-2xl text-sm text-gray-400 italic pr-8 p-4 w-40 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'>
                      <option value="-Select-">-Select-</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
                <div className='flex mb-4 justify-between mt-3'>
                  <div className='flex flex-col ml-6'>
                    <label htmlFor="country" className='ml-3 mb-1 text-xs text-gray-400'>Country</label>
                    <select onChange={handlerChange} name="country" className='border rounded-2xl text-sm text-gray-400 italic pr-8 p-4 w-40 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'>
                      <option value="-Select-">-Select-</option>
                      <option value="Argentina">Argentina</option>
                    </select>
                  </div>
                  <div className='flex flex-col mr-6'>
                    <label htmlFor="town" className='ml-3 mb-1 text-xs text-gray-400'>Town</label>
                    <select onChange={handlerChange} name="town" className='border rounded-2xl text-sm text-gray-400 italic pr-8 p-4 w-40 md:w-64 lg:w-48 focus:outline-none focus:border-violet-600'>
                      <option value="-Select-">-Select-</option>
                      <option value="Town">Town</option>
                    </select>
                  </div>
                </div>
                <div className='flex ml-7 mt-6'>
                  <input onChange={handlerChange} type='checkbox' className='w-4 border border-zinc-600' name="term-privacy"/>
                  <div className='ml-2 text-xs'>I agree with <a href="htts://www.google.com" className='text-sky-600'>Terms</a> and <a href="https://wwww.google.com" className='text-sky-600'>Privacy Policy</a></div>
                </div>
                <div className='text-center'>
                  <button className='flex m-auto mt-10 text-slate-400 border-2 text-xs font-bold px-14 py-4 rounded-3xl active:bg-violet-600 active:text-white transition-all'>Register account</button>
                </div>
            </div>
          </form>
        </>  
      )}

    </div>
  )
}

export default CreateNewAccountView;