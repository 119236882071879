import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../..//assets/svg/logo.svg'

// const people = [
//   {
//     name: 'Calvin Hawkins',
//     email: 'calvin.hawkins@example.com',
//     image:
//       'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Kristen Ramos',
//     email: 'kristen.ramos@example.com',
//     image:
//       'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
//   {
//     name: 'Ted Fox',
//     email: 'ted.fox@example.com',
//     image:
//       'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//   },
// ]

function HomeView() {
  return (
    <>
      {/* <Nav/> */}
      <div className="bg-gradient-to-tr from-[#863EED] justify-center items-center via-[#8044EB] via-[#7155E8] via-[#5972E3] to-[#3A98DD] h-screen flex flex-col justify-content content-center">
        <div className="flex flex-col p-4 max-w-md">
          <img src={logo} alt="logo" className='' />
          <p className='font-poppins text-white text-center'>Talk, chat, exchange and learn<br/> any language</p>
        </div>
        <div>
          <Link to='/create-new-account'>
            <button className='block mt-8 bg-[#F7F8FA] hover:bg-[#3A98DD] hover:text-white hover:w-lg text-[#863EED] font-poppins text-bold  py-4 px-10 mx-auto rounded-full shadow shadow-lg'>Create new account</button>
          </Link>
          <Link  to='/into-login'>
            <button className='block w-60 mt-8 hover:bg-[#3A98DD] text-white font-poppins text-bold  py-4 px-10 mx-auto rounded-full'>Sing In</button>
          </Link>
        </div>
        {/* <h3>Find a partner to practice</h3>
        <Link to='/search'>
          <button type='submit' className='mt-8 bg-sky-500 hover:bg-sky-600 text-white font-bold py-5 px-5 my-3 rounded w-full'>Search</button>
        </Link> */}
      </div>
    </>

  )
}

export default HomeView