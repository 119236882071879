import { IUserState } from '../store/storeProvider'

export const UserReducer = (state: IUserState, action: {type: string, payload: any}) => {  
  switch (action.type) {
    case 'SELECT_AVATAR':      
      return {...state, avatarID: action.payload};
    case 'SET_ABOUT_YOURSELF':  
      return {...state, textAboutYourself: action.payload};
    case 'SET_INFO':  
      return {...state, ...action.payload};
    case 'SET_LANGUAGES':  
      return {...state, languages: action.payload};
    default:
      return state;
  }
}
