import React, { createContext, useReducer } from "react";
import { UserReducer } from './storeReducer';


export type IUserState = {
  avatarID: number | undefined;
  username: string;
  languages?: any[],
  textAboutYourself?: string
  selectAvatar?: (id: string) => void;
}

type ContextProps = {
  state: IUserState;
  selectAvatar: (id: string) => void;
  setAboutYourself: (text: string) => void;
  setInfo: (el: any) => void;
  setLanguages: (el: any) => void;
}

export const AppCtx = createContext<ContextProps | null>(null);

const UserContext: IUserState | {} = {};

interface props {
  children: JSX.Element | JSX.Element[]
}

export const StoreProvider = ({children}:props) => {

  const [state, dispatch] = useReducer( UserReducer , UserContext );

  const selectAvatar = (id: number | string) => {
    dispatch({type: 'SELECT_AVATAR', payload: id})
  }
  const setAboutYourself = (text: string) => {
    dispatch({type: 'SET_ABOUT_YOURSELF', payload: text})  
  }
  const setInfo = (obj: any) => {
    dispatch({type: 'SET_INFO', payload: obj})  
  }
  const setLanguages = (arr: any) => {
    dispatch({type: 'SET_LANGUAGES', payload: arr})  
  }
  return <AppCtx.Provider value={{
    state,
    selectAvatar,
    setAboutYourself,
    setInfo,
    setLanguages,
  }}>{children}</AppCtx.Provider>
}
