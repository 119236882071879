import { useState } from "react";
import { sendContactEmail } from "../../../../services/emails";

function ButtonSendMessage( props: any ) : JSX.Element {
  const [btnState, setBtnState] = useState(props.state);
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    console.log(props.userId);
    setIsLoading(!isLoading);
    setTimeout( () => {
      setBtnState(!btnState);
    }, 900)
    try {
      sendContactEmail(props);
    } catch (error) {
      
    }
    
    
  };

  if(btnState) {
    return (
      <button onClick={handleClick} type='submit' className='md:mid-w-[40%] min-w-[68%] flex ml-auto justify-center items-center text-white border-2 text-xs px-11 py-3 mt-2 rounded-3xl active:bg-violet-300 bg-violet-600 active:text-white transition-all'>  
        {isLoading ?  
          ( 
            <svg  fill="transparent" className="animate-spin h-6 w-6 mr-3 text-white" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          ) : 
          <>
            <p>Send Message</p>
            <span className="ml-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>
            </span>
          </>
        }
      </button>
    )
  } else {
    return (
      <button type='submit' className='md:mid-w-[40%] min-w-[68%] flex justify-center items-center ml-auto text-white border-2 text-xs px-11 py-3 mt-2 rounded-3xl bg-[#19CC61] transition-all'>
        Message sent 
        <span className="ml-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
        </span>
      </button>
    )
  }
};

export default ButtonSendMessage;