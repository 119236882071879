import React, {useState, FormEvent } from 'react'
import { Link } from 'react-router-dom'
import { Nav } from '../../components';
const inputClass = `mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1`;

const spanClass = `block text-md font-medium text-slate-700`;



function LoginView() {
  const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
    console.log(form);
  };
  
  const handlerChange = (e: any) => {
    setForm(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }))
  }
  
  const [form, setForm] = useState({})
  return (
    <>
    <Nav/>
    <div className='my-10'>
      <h3 className='text-3xl font-bold text-center'>Login</h3>
      <div className='flex justify-center'>
        <form onSubmit={handleSubmit}  id="register" action="" className='max-w-md w-full mx-2 my-5 space-y-4 flex flex-col' >
          <div>
            <label className="block">
              <span className={spanClass}>
                Email
              </span>
              <input onChange={handlerChange} type="email" name="email" className={inputClass} placeholder="you@example.com" />
            </label>
          </div>
          <div>
            <label className="block">
              <span className={spanClass}>
                Password
              </span>
              <input onChange={handlerChange} type="password" name="password" className={inputClass} placeholder="Password" />
            </label>
          </div>
          <div>
            <Link to='/search'>
              <button type='submit' className='mt-8 bg-sky-500 hover:bg-sky-600 text-white font-bold py-5 px-5 my-3 rounded w-full' >Login</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
    </>
  )
}

export default LoginView 