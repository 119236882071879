import React, {useState, FormEvent } from 'react';
import {Link} from 'react-router-dom'
import { Nav } from '../../components';
const inputClass = `mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1`;

const spanClass = `after:content-['*'] after:ml-0.5 after:text-red-500 block text-md font-medium text-slate-700`;

function RegisterView() {
  const handleSubmit = (e : FormEvent) => {
    e.preventDefault();
    console.log(form);
  };
  
  const handlerChange = (e: any) => {
    setForm(state => ({
      ...state,
      [e.target.name]: e.target.value,
    }))
  }
  
  const [form, setForm] = useState({});

  return (
    <>
      <div className='my-10'>
        <h3 className='text-3xl font-bold text-center'>Register</h3>
        <div className='flex justify-center'>
          <form onSubmit={handleSubmit}  id="register" action="" className='max-w-md w-full mx-2 my-5 space-y-4 flex flex-col' >
            <div>
              <label className="block">
                <span className={spanClass}>
                  Username
                </span>
                <input type="text" name="username" onChange={handlerChange} className={inputClass} placeholder="Username" />
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Email
                </span>
                <input onChange={handlerChange} type="email" name="email" className={inputClass} placeholder="you@example.com" />
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Password
                </span>
                <input onChange={handlerChange} type="text" name="password" className={inputClass} placeholder="Password" />
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Gender
                </span>
                <select className={inputClass} onChange={handlerChange} name="gender" id="">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Date
                </span>
                <input onChange={handlerChange} type="date" name="date" className={inputClass + 'w-full'} placeholder="Date" />
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Country
                </span>
                <select onChange={handlerChange} className={inputClass} name="country" id="">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Town
                </span>
                <select onChange={handlerChange} className={inputClass} name="town" id="">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  State
                </span>
                <select onChange={handlerChange} className={inputClass} name="state">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Language
                </span>
              </label>
              <select onChange={handlerChange} className={inputClass} name="language">
                <option value="">1</option>
                <option value="">2</option>
                <option value="">3</option>
                <option value="">4</option>
              </select>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                Level
                </span>
                <select onChange={handlerChange} className={inputClass} name="level">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label className="block">
                <span className={spanClass}>
                  Hobbies
                </span>
                <select onChange={handlerChange} className={inputClass} name="hobbies">
                  <option value="">1</option>
                  <option value="">2</option>
                  <option value="">3</option>
                  <option value="">4</option>
                </select>
              </label>
            </div>
            <div>
              <label htmlFor="">Description</label>
              <textarea  onChange={handlerChange} name="about" rows={3} className={inputClass} placeholder="description"></textarea>
            </div>
            <div>
            <Link to='/search'>
              <button type='submit' className='mt-8 bg-sky-500 hover:bg-sky-600 text-white font-bold py-5 px-5 my-3 rounded w-full'>Register</button>
            </Link>
             
            </div>
          </form>
        </div>
      </div>
      <Nav />
    </>
  )
}

export default RegisterView 