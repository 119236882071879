export const MockUsers = [
  {
    id: 1,
    username: 'Rodrigo Bueno',
    avatar_id: 2,
    country: 'argentine',
    date_of_birth: new Date('12-12-2000'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'English'}, {level: 3, name: 'French'}, {level: 2, name: 'Portuguese'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date('2022-11-27'),
    contact_info: [{info: 'somukmail', name: 'email'}],
  },
  {
    id: 2,
    username: 'Walter White',
    avatar_id: 1,
    country: 'argentine',
    date_of_birth: new Date('12-12-2000'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'Spanish'}, {level: 1, name: 'English'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date('2022-11-26'),
    contact_info: [{info: 'somukmail2', name: 'email'}],
  },
  {
    id: 3,
    username: 'Walter White',
    avatar_id: 7,
    country: 'france',
    date_of_birth: new Date('12-12-2000'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'Spanish'}, {level: 1, name: 'English'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date('2022-11-22'),
    contact_info: [{info: 'somukmail2', name: 'email'}],
  },
  {
    id: 4,
    username: 'Walter White',
    avatar_id: 0,
    country: 'united_state',
    date_of_birth: new Date('12-12-1999'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'Spanish'}, {level: 4, name: 'English'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date('2022-11-20'),
    contact_info: [{info: 'somukmail2', name: 'email'}],
  },
  {
    id: 5,
    username: 'Walter White',
    avatar_id: 4,
    country: 'united_state',
    date_of_birth: new Date('12-12-1998'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'Spanish'}, {level: 4, name: 'English'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date(),
    contact_info: [{info: 'somukmail2', name: 'email'}],
  },
  {
    id: 6,
    username: 'Walter White',
    avatar_id: 6,
    country: 'france',
    date_of_birth: new Date('12-12-1990'),
    description: 'Hello, i am Roberto. Originally from Argentina and lover of the world. I love learning new languages ​​and meeting people. Lets chat.',
    gender: 'male',
    hobbies: ['tennis', 'books','music'],
    languages: [{level: 4, name: 'Spanish'}, {level: 4, name: 'English'}],
    state: 'buenos aires',
    town: 'buenos aires',
    last_connection: new Date(),
    contact_info: [{info: 'somukmail2', name: 'email'}],
  },
];