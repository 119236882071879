import React from 'react';

function MessageView() {
  return (
    <div className='flex flex-col max-w-xl m-auto h-auto border border-gray-300 rounded-3xl'>
      <div className='w-28 md:w-60 lg:w-48 ml-7 mt-24 text-sky-500 font-bold text-lg'>Sign in to your account</div>
      <form className='mt-8 mb-28 flex flex-col'>
        <div className='flex flex-col ml-6 mr-6 mb-4'>
          <label htmlFor='email' className='ml-3 mb-1 text-xs text-gray-400'>E-mail address</label>
          <input type="email" placeholder="E-mail" className="border-2 p-4 rounded-2xl pl-3 mb-4 text-sm"/>
        </div>
        <div className='flex flex-col ml-6 mr-6'>
          <label htmlFor='password' className='ml-3 mb-1 text-xs text-gray-400'>Your Password</label>
          <input type="password" placeholder="Password" className="border-2 p-4 rounded-2xl pl-3 mb-6 text-sm placeholder:text-[#de0707]"/>
        </div>
        <div className='text-xs text-sky-500 flex justify-end mr-10 cursor-pointer hover:underline'>Forgot password?</div>
        <button className='flex m-auto mt-7 bg-violet-600 text-white text-xs font-bold px-14 py-4 rounded-3xl hover:bg-violet-800 transition-all'>Sign in</button>
      </form>
    </div>
  )
}

export default MessageView;